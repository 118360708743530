.app 
    .rents
        display: flex
        flex-wrap: wrap
        gap: 30px
        .card.unpaid-rents,
        .card.unpaid-reminders
            width: calc(50% - 15px)
        .card.mandate
            width: 100%
            p
                text-align: center
                margin: 0
    
    .table-reminders,
    .table-calls
        .picto-table-wrapper,
        .loading
            display: inline-block
            vertical-align: middle
        .picto-table
            height: 35px
            transition: all 0.3s
            margin: 0 10px
            padding: 4px

@media screen and (max-width: $screen-xl) 
    .app .rents
        flex-direction: column
        .card
            width: 100% !important