.app .login
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 100px
    .page-title 
        display: flex
        justify-content: center
        width: 800px
        h2
            width: 100%
            text-align: center
            font-size: 30px
            padding-bottom: 20px
            border-bottom: 2px solid white
    .login-wrapper
        width: 700px
        .segment
            height: fit-content
            width: 100%
            img
                margin-right: 15px
                width: 30px
                height: 30px
                width: fit
                background-color: $second-blue
                border-radius: 50%
                padding: 5px
                &:hover
                    background-color: white
                
            .forgot
                font-size: 15px

@media screen and (max-width: $screen-md)
    .app .login
        .page-title
            width: 550px
            font-size: 20px !important
        .login-wrapper
            width: 500px
            input
                height: 30px
        .forgot
            font-size: 12px !important
        .legal-links a
            font-size: 9px !important

@media screen and (max-width: $screen-sm)
    .app .login
        .page-title
            width: 290px
        .login-wrapper
            width: 290px