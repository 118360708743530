.app .contact
    .help-header
        margin-bottom: 20px
        p
            margin: 0
    .contact-forms
        display: flex
        flex-direction: row
        gap: 30px
        .segment
            display: flex
            flex-direction: column
            width: 50%
            form
                flex-grow: 1
        .form-item-list textarea
            height: 150px
    .download-btn
        margin-top: 10px

@media screen and (max-width: $screen-xl)
    .app .contact
        .contact-forms
            flex-direction: column
            .segment
                width: 100%