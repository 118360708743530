.app .dashboard
    display: flex
    flex-wrap: wrap 
    justify-content: space-between
    gap: 30px
    .card.solde 
        width: calc(40% -  15px)
        min-height: 300px
        max-height: 370px
        .card-body p
            text-align: center
            padding: 20px
            font-style: italic
    .card.notifications
        width: calc(60% - 15px)
        min-height: 300px
        max-height: 370px
        .card-body
            .notification
                position: relative
                padding-left: 25px
                &:before
                    content: ""
                    position: absolute
                    top: 0
                    left: 0 
                    transform: translateY(90%)
                    height: 10px    
                    width: 10px
                    background-color: $main-blue
                    border-radius: 50%
                .notification-chip
                    position: relative
                    padding-left: 25px
                    &:before
                        content: ""
                        display: block
                        position: absolute
                        top: 50%
                        left: 0
                        transform: translateY(-50%)
                        height: 10px
                        width: 10px
                        background-color: white
                        border: 2px solid $main-blue
                        border-radius: 50%

                span
                    font-weight: $bold
                a
                    font-weight: $bold
                    text-transform: uppercase        
            &:last-child p
                margin-bottom: 0
    .card.incidents
        width: calc(60% - 15px)
        .table-incidents-dashboard
            border-radius: 0
            thead,
            tbody
                background-color: transparent
                th,
                td
                    border: 2px solid $border-color
                td
                    text-align: left
    .card.property-admin
        width: calc(40% - 15px)
        a
            text-decoration: none

@media screen and (max-width: $screen-xl)
    .app .dashboard
        flex-direction: column
        .card
            width: 100% !important

@media screen and (max-width: $screen-xs)
    .app .dashboard .notification
        padding-left: 20px
        &::before
            left: -5px
            width: 12px
            height: 12px