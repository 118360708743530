.app .form
    display: flex
    flex-direction: column
    flex-grow: 1
    font-size: 22px
    width: 100%
    .form-item-list
        display: flex
        flex-direction: column
        input,
        textarea
            display: inline-block
            width: 100%
            margin-right: 10px
            border-radius: 5px
            font-size: 18px
            line-height: 1
            color: $grey
            padding: 5px
            border: none
        .file-name
            font-style: italic
            font-size: 17px 
            text-decoration: underline
    .form-item
        display: flex
        margin-bottom: 12px
        color: $main-blue
        span
            color: red
    .form-item-file-wrapper
        display: flex
        flex-direction: column
        width: 100%
        .form-item-file
            display: flex
            flex-direction: column
            gap: 15px
            font-size: 18px
            .input-file
                display: none
            .upload-file
                font-weight: $bold
    .date-fields
        display: flex
        flex-direction: column
        gap: 10px
        width: 50%
        .date-fieldset 
            display: flex
            input
                width: 80px
                font-size: 15px
            .fake-label
                width: 150px
                font-weight: $bold
                margin-right: 10px
                span
                    color: red
    .submit-button-wrapper
        flex-grow: 1
        display: flex
        align-items: flex-end
        margin-top: 15px 
    
    .password-strength
        display: flex
        flex-direction: row
        gap: 10px
        .security
            width: 70px
            height: 15px
            border-radius: 5px
            background-color: white
            &.green
                background: $green
            &.orange
                background: $orange
            &.red
                background: $red
        
    .form-group
        display: flex
        flex-direction: column
    label,
    .fake-label
        display: inline-block
        font-weight: $bold
        font-size: 18px
        min-width: 0
        width: fit-content
        margin: 0
        margin-bottom: 5px
    input:-ms-input-placeholder, /* I.E. */
    input::-ms-input-placeholder, /* Microsoft Edge */
    input::placeholder
        color: $light-grey
        opacity: 1
        input,
        textarea
            display: inline-block
            flex-grow: 1
            border-radius: 5px
            line-height: 1
            color: $main-blue
            font-size: 18px
            padding: 8px
            border: none
        textarea
            height: 150px            
    .form-item-list .form-item-check-box
        display: none
    .form-item-check-box-label
        padding-left: 40px
        position: relative
        margin: 0 auto
        &:before,
        &:after
            display: block
            position: absolute
            top: 50%
            transform: translateY(-50%)
        &:before
            content: ""
            left: 0
            height: 30px
            width: 30px
            border: 2px solid $grey
        &:after
            content: url('../../images/pictogrammes/checked-grey.svg')
            left: 3px
            height: 24px
            width: 24px
            transition: all 0.3s
            opacity: 0
        &.checked:after
            opacity: 1
    .form-item-file-wrapper
        display: flex
        .fake-label
            display: inline-block
        .form-item-file
            .form-group
                margin: 0
                &.checked:after
                    background-image: url("../../images/pictogrammes/checked-white.svg")
                    background-repeat: no-repeat
                    background-position: right 10px center
                    background-size: auto 16px
       

@media screen and (max-width: $screen-xl)
    .app
        .form .form-item-list
            display: flex
            flex-direction: column !important
            .date-fields
                margin-bottom: 20px
                width: 50%
                .date-fieldset
                    display: flex
                    flex-direction: column
                    gap: 10px
                    input
                        width: 100%

        .form .form-item-list-incident
            display: flex
            flex-direction: column
            .fields
                width: 100%
                margin: 0

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .form
        font-size: 18px
        .form-item
            display: block
            input,
            textarea
                display: block
                width: 100%
                padding: 5px 10px   