.app .infos
    display: flex
    flex-direction: column
    gap: 10px
    .infos-forms
        display: flex
        flex-direction: row
        gap: 10px
        .segment
            display: flex
            flex-direction: column
            margin: 0
            width: 100%
            .form
                .submit-button-wrapper
                    justify-content: center
            h2
                text-align: center
                font-size: 25px
                font-weight: $bold
                
                padding: 10px
                margin-bottom: 15px
                text-transform: uppercase
            p
                font-size: 15px
                padding: 15px 0
            .cgu
                font-size: 15px
                padding-bottom: 10px
                .cgu-title
                    font-weight: $bold

@media screen and (max-width: $screen-lg)
    .app .infos .infos-forms
        display: flex
        flex-direction: column
    