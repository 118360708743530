.app .table
    width: 100%
    margin: 0
    &.horizontal
        border-top-left-radius: 10px
        border-top-right-radius: 10px
        overflow: hidden
        thead
            text-align: center
            text-transform: uppercase
        td
            text-align: center
            
    thead 
        color: $main-blue
        background-color: $second-blue
        font-size: 18px
    tbody
        border: 0
        background: white
        font-size: 18px
        &.reverse-color
            .tr-colored
                background-color: $bg-blue
    th
        padding: 10px
        font-weight: $bold
        border: 0
        vertical-align: middle
    td
        border: 0
        vertical-align: middle

    .ellipsed
        overflow-x: auto
        text-align: justify
        -moz-box-orient: vertical
        -webkit-box-orient: vertical
        display: flex
        display: -moz-box
        display: -webkit-box
        overflow: hidden
        text-overflow: ellipsis
        -moz-line-clamp: 4
        -webkit-line-clamp: 4
        line-height: 1.5rem
        max-height: 4 * 1.5rem
        min-width: 300px

    .title,
    .subtitle,
    .total,
    .subtotal
        color: $blue
        font-weight: $bold
    .subtotal,
    .subsubtotal
        color: $blue
        background-color: transparentize($light-grey, 0.4)
    .subsubtotal
        font-weight: $medium

    .highlight
        font-weight: $medium
    .amount
        white-space: nowrap
    .date
        white-space: nowrap
    .amount-negative
        color: $red
    .amount-positive
        color: $green

    .picto-table
        height: 25px
        &:hover,
        &:focus,
        &:active
            cursor: pointer
    a.picto-table
        display: inline-block
        text-indent: -9999px
        height: 25px
        width: 25px

    .table-hidden-rows
        .animated-cell
            transition: max-height 0.5s
            overflow: hidden
            text-overflow: ellipsis
            text-align: justify
    
        .hideable
            border-top: 1px solid white
            td
                background-color: $light-grey
        .hidden
            border-top: 0
            overflow: hidden
            &:after
                content: none
            td
                padding-top: 0
                padding-bottom: 0
                transition: all 0.3s
                .animated-cell
                    max-height: 0

@media screen and (max-width: $screen-xs), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app .table
        th,
        td,
        .table-hidden-rows tr td
            padding: 5px

        &.horizontal
            th
                white-space: normal
            td  
                width: auto
