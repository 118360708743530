.app .btn
    position: relative
    padding: 0
    border: 0
    background-color: transparent
    color: white
    white-space: normal
    font-size: 15px
    font-weight: $medium
    text-transform: uppercase
    text-decoration: none
    transition: all 0.3s
    overflow: hidden
    z-index: 1
    &:before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        transform: translateX(-101%)
        transition: all 0.35s
        z-index: -1
    &:after
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        transition: all 0.3s
        z-index: -1
    &:focus
    &:hover,
    &:focus,
    &:active,
    &.active
        box-shadow: none
        color: $card-header-blue
        text-decoration: underline
        &:before
            transform: translateX(0)
            transition: all 0.25s
        &:after
            transform: translateX(101%)
    &.active
        z-index: 2
    &.incident-state-btn
        margin-right: 20px
        font-size: 18px
        font-weight: $bold

    &.file-btn
        background-color: transparent
        color: $main-blue
        margin-top: 10px
        border: 2px solid $main-blue
        padding: 5px 30px
        text-decoration: none
        margin-bottom: 10px
        font-size: 17px
        font-weight: $bold
        &:hover,
        &:focus,
        &:active
            background-color: $main-blue
            color: $bg-blue

    &.main-btn
        position: relative
        color: $main-blue
        padding: 5px 30px
        font-weight: $bold
        font-size: 15px
        text-decoration: none
        text-transform: uppercase
        background-color: $second-blue
        &:hover,
        &:focus
            color: white
        &:after
            background-color: $second-blue
        &:before
            background-color: $main-blue

    &.outline-btn
        text-decoration: none
        font-size: 15px
        font-weight: $bold          
        border-radius: 5px
        width: fit-content
        padding: 5px 30px
        &.white
            color: white
            border: 2px solid white
            &:hover
                background-color: white
                color: $main-blue
        &.blue
            color: $main-blue
            border: 2px solid $main-blue
            &:hover
                background-color: $main-blue
                color: white

    &.secondary-btn
        color: $main-blue
        font-weight: $bold
        text-decoration: underline

    &.epayment-btn
        display: flex
        gap: 20px
        width: 50%
        .btn
            &:hover,
            &:focus,
            &:active
                border: 2px solid white
        
    &.btn-print
        margin-left: auto

    &.disabled-btn
        background-color: $light-grey
        padding: 5px 30px
        color: $main-blue
        font-weight: $bold
        text-decoration: none

.btn-wrapper
    display: flex
    flex-direction: row
    gap: 30px
    margin-top: 15px
    justify-content: center
    .btn:first-child:last-child
        margin: 0


.toggle-btn-wrapper
    margin: 10px 0 20px 0
            
.app .lease-teaser
    .btn
        color: white
        background-color: $orange
    .btn:after
        background-color: $orange
    .btn:hover,
    .btn:focus,
    .btn:active,
    .btn.active
        color: $orange
    .btn:before
        background-color: white
    .btn.light-btn
        color: $orange
        background-color: white
    .btn.light-btn:hover,
    .btn.light-btn:focus,
    .btn.light-btn:active,
    .btn.light-btn.active
        color: white
    .btn.light-btn:before
        background-color: $orange
    .btn.grey
        color: white
        &:after
            background-color: $grey
        &:hover,
        &:focus,
        &:active,
        &.active
            color: $grey
        &:before
            background-color: $light-grey

    &:hover,
    &:focus,
    &:active
        .btn
            color: $orange
            &:before
                transform: translateX(0)
            &:after
                transform: translateX(100%)
            &.light-btn
                color: white

@media screen and (max-width: $screen-md)
    .app .btn
        font-size: 12px !important
        padding: 5px !important