.app
    .payments
        display: flex
        flex-wrap: wrap
        gap: 30px
        h1
            font-weight: $bold
        .payments-methods
            display: flex
            gap: 30px
            width: 100%
            .card
                width: calc(50% - 15px)
                p
                    padding: 20px 0
                    text-align: center                 
                .main-btn
                    width: 50%
        .message
            width: 100%
            text-align: center
            padding: 10px
            background: $border-color
            border-radius: 5px
            p
                font-style: italic
                color: $main-blue
        .card.solde
            width: calc(40% - 15px)
            min-height: 300px
            max-height: 370px
            .card-body
                p
                    text-align: center
                    padding: 20px
                    font-size: 17px
                    font-style: italic
        .card.history
            width: calc(60% - 15px)
            min-height: 300px
            max-height: 370px

    .direct-debit
        .segment
            width: 50%
            .text-rib
                font-weight: $bold
                margin-bottom: 30px
            .submit-button-wrapper
                align-self: flex-start

    .epayment
        display: flex
        flex-direction: column
        gap: 30px
        height: 92%
        .segment
            background: $card-header-blue
            &.amount,
            &.dates
                display: flex
                flex-direction: row
                align-items: center
                gap: 30px
                h4
                    width: 20%
                .date-wrapper
                    display: flex
                    justify-content: space-between
                    gap: 30px
                    width: 100%
                    .date
                        padding: 20px
                        width: 100%
                        color: $main-blue
                        border-radius: 5px
                        border: 0
                        cursor: pointer
                        &:hover,
                        &:focus,
                        &:active
                            background: $main-blue
                            color: white
                            border: none
                            outline: none
            &.amount
                input
                    width: 100%
                    padding: 20px
                    border: 0
                    border-radius: 5px
            &.banking-info
                display: flex
                flex-direction: column
                gap: 15px
                height: 100%
                .content
                    display: flex
                    gap: 30px
                    flex-direction: row
                    height: 100%
                    .user-info
                        width: 40%
                        background: white
                        padding: 20px
                    .btn-wrapper
                        display: flex
                        width: 60%
                        justify-content: space-between
                        align-self: flex-end
                        .paid-btn
                            color: white
                            text-decoration: none
                            padding: 5px 30px
                            font-weight: $medium
                            text-transform: uppercase
                            background-color: $main-blue
                            &:hover,
                            &:focus
                                color: $main-blue
                                background: white
                            &:after
                                background-color: $main-blue
                            &:before
                                background-color: white

@media screen and (max-width: $screen-xl)
    .app
        .payments
            flex-direction: column 
            .card
                &.solde,
                &.history
                    width: 100%
        .payments-methods
            display: flex 
            flex-direction: column
            .card
                width: 100% !important
        .direct-debit
            .segment
                width: 100%
        .epayment
            .epayment-btn
                flex-direction: column
                width: 100%
            .segment
                &.amount,
                &.dates
                    flex-direction: column
                    h4
                        width: fit-content
                    .date-wrapper
                        overflow-y: auto
                
                &.banking-info
                    .content
                        flex-direction: column
                        .user-info,
                        .btn-wrapper
                            width: 100%