.app 
    .lease
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 30px
        .my-lease
            width: calc(55% - 15px)
        .rent
            width: calc(45% - 15px)
        .renew
            flex-grow: 1
            p
                margin: 0
    
    .table-lease
        .lease-file
            display: flex
            flex-direction: row
            gap: 10px
        th
            width: 30%
        td
            display:  flex
            align-items: center
            gap: 10px
        .file-name
            display: flex
            align-items: flex-start
        
    .form.insurance,
    .form.heater
        .form-item-list
            display: flex
            flex-direction: row

    .segment.insurance,
    .segment.heater
        width: 75%

@media screen and (max-width: 1460px)
    .app .segment.insurance,
    .app .segment.heater
        width: 100%

@media screen and (max-width: 1400px)
    .app .lease
        display: flex
        flex-direction: column
        .card
            width: 100%
        