.loading
    display: block
    position: relative
    width: 38px
    height: 38px
    margin: 0 auto
    padding-bottom: 15px
    &:before
        background-color: red
    div
        box-sizing: border-box
        display: block
        position: absolute
        width: 30px
        height: 30px
        margin: 4px
        border: 4px solid #fff
        border-radius: 50%
        animation: loading 1.2s cubic-bezier(0.2, 0.45, 0.7, 0.2) infinite
        border-color: $blue transparent transparent transparent
        &:first-child
            animation-delay: -0.05s
            border: 4px solid transparentize($blue, 0.7)
        &:nth-child(2)
            animation-delay: -0.1s
        &:nth-child(3)
            animation-delay: -0.15s

@keyframes loading
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)