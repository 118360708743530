// Modal
.app .overlay
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    background-color: transparentize($grey, 0.5)
    z-index: 999
    padding: 20px
    .modal
        display: flex
        position: relative
        width: 800px
        max-width: 100%
        max-height: 100vh
        min-width: 0
        border: 4px solid $main-blue
        border-radius: 10px
        background-color: white
        .modal-scrollbar
            overflow: auto
            scrollbar-color: $light-grey white
            scrollbar-width: thin
            max-height: 100%
            padding: 20px
            flex-grow: 1
            &::-webkit-scrollbar
                background-color: white
                height: 12px
                width: 12px
            &::-webkit-scrollbar-thumb
                background-color: $light-grey
            .inner-modal
                margin: 0 auto
                p
                        color: $main-blue
                .close-modal
                    position: absolute
                    top: 7px
                    left: 7px
                    background-color: transparent
                    border: 0
                    cursor: pointer
                    padding: 0
                    line-height: 1
                    z-index: 10
                    img
                        width: 20px
                        height: 20px
                        transition: all 0.3s
                    &:hover,
                    &:focus,
                    &:active
                        img
                            transform: rotate(90deg)
                .submit-button-wrapper
                    justify-content: center

@media screen and (max-width: $screen-sm)
    .app .overlay
        padding: 0
        .modal
            border: 0
            border-radius: 0
            .modal-scrollbar
                padding: 15px
