.app
    .legal-notice-page,
    .legal-notice
        h4
            text-decoration: underline
            margin-bottom: 20px
    .legal-notice-page
        padding: 50px 160px
        .segment h3 
            margin-bottom: 30px

@media screen and (max-width: $screen-lg)
    .app .legal-notice-page
        padding: 40px 50px
    