.app 
    header
        height: $header_height
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        background-color: $main-blue
        color: white
        font-size: 23px
        white-space: nowrap
        a
            height: 100%
            margin: 20px
            color: white


        .menu-top .user-menu .user-menu-title
            font-weight: $bold
            overflow: hidden
            text-overflow: ellipsis

        .header-title
            display: flex
            flex-direction: row
            align-items: center
            overflow: hidden
            text-transform: uppercase
            gap: 30px
            h1
                padding-bottom: 20px 
                border-bottom: solid 2px white
                width: fit-content
                font-size: 30px
                
                margin: 0
    
    .page-title
        display: flex
        flex-direction: row
        align-items: center
        margin: 10px 0 25px 0
        h2
            color: white
            font-weight: $bold
            font-size: 25px
            text-transform: uppercase
            margin: 0
        img
            height: 25px
            width: 25px
            background: $second-blue
            border-radius: 50%
            padding: 5px
            margin-right: 17px
            &:hover,
            &:focus,
            &:active
                background: white

@media screen and (max-width: $screen-md)
    .app 
        header .header-title
            display: none
        .page-title
            h2
                font-size: 17px

@media screen and (max-width: $screen-lg)
    .app header
        padding-left: 70px
        a
            padding-left: 20px
            padding-right: 20px 
        .menu-top 
            > a 
                display: none