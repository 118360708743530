.app .my-tenant-account
    min-height: 215px
    h3
        font-size: 22px
        font-weight: $bold
    .table-account a.picto-table
        background: url('../../images/pictogrammes/file-blue.svg') no-repeat center center

@media screen and (max-width: $screen-md)
    .app .my-tenant-account .page-title
        display: flex
        flex-direction: column
        gap: 10px
        align-items: flex-start
        button
            margin: 0