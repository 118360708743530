.app 
    .file-toggle
        display: flex
        width: 125px
        border-radius: 5px
        border: 2px solid white
        .file-table,
        .file-list
            display: flex
            justify-content: space-around
            align-items: center
            width: 50%
            height: 25px
            border: none
            background: none
            padding: 0
            &:focus
                outline: none
            &:hover,
            &:focus,
            &:active,
            &.active
                background: $second-blue
        .file-table
            border-right: 2px solid white
            border-top-left-radius: 2px
            border-bottom-left-radius: 2px
            & > *:only-child
                margin-left: 28px
        .file-list
            border-top-right-radius: 2px
            border-bottom-right-radius: 2px
            & > *:only-child
                margin-left: 30px
        img
            width: 15px
            height: 15px

    .breadcrumb-container
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 20px
    
    .breadcrumb
        display: flex
        justify-content: space-between
        align-items: center
        color: $card-header-blue
        background: none
        padding: 0
        margin: 0
        height: auto
        font-weight: $bold
        .breadcrumb-anchor
            cursor: pointer
            transition: all 0.3S
            &:hover,
            &:focus,
            &:active
                text-decoration: underline

    .folder-content
        display: grid
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
        color: white
        justify-items: center
        .folder,
        .file
            position: relative
            margin-bottom: 35px
            width: max-content
            cursor: pointer
            text-align: center
            font-size: 14px
            a
                text-decoration: none
            .picto-folder,
            .picto-file
                transition: all 0.2s
                width: 70px
                margin-bottom: 10px
            .folder-name,
            .file-name
                display: block
                font-weight: $medium
                margin: 0
                text-align: center
                font-size: 15px
            &:hover .picto-folder,
            &:hover .picto-file
                padding: 10px
        .folder:before
            content: ''
            position: absolute
            display: block
            left: 0  
            top: 0
            right: 0
            bottom: 0
            z-index: 1
        .file
            .picto-file
                height: 70px
            a:before
                content: ''
                position: absolute
                display: block
                left: 0  
                top: 0
                right: 0
                bottom: 0
                z-index: 1
    
    .table-file
        tr
            td
                width: 50%
        .folder
            display: flex
            align-items: center
            gap: 10px
            width: 100%
            cursor: pointer
            justify-content: center
        .file
            background: $light-grey
        .file-name
            img
                width: 30px
                height: 30px
        .picto-folder
            width: 30px
            height: 30px
        .picto-arrow
            width: 15px
            height: 15px
            transform: rotate(90deg)
            transition: all 0.3s
            &.active
                transform: rotate(0deg)
                transition: all 0.3s

@media screen and (max-width: $screen-sm)
    .app
        .breadcrumb-container
            flex-direction: column
            gap: 10px
            align-items: flex-start