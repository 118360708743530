.app aside
    width: $aside_width
    min-width: $aside_width // due to flex
    color: white
    font-size: 25px

    .menu-burger
        display: none

    .lease-name
        display: flex
        justify-content: center
        width: 100%
        color: $second-blue
        font-size: 15px
        padding-top: 10px

    .aside-content-wrapper
        height: 100%
        display: flex
        flex-direction: column
        // Edge fallback
        overflow: auto
        overflow: hidden auto
        padding-top: 20px
        position: fixed
        background-color: white
        width: $aside_width // due to fixed
        min-width: $aside_width

        .logo-img-wrapper
            display: flex
            flex-direction: column
            align-items: center
            width: 100%
            .profile-img
                width: 75px
                height: 75px
            h1
                font-size: 20px
                
                font-weight: $bold
                color: $main-blue
                text-align: center
        
        nav
            min-height: 1px
            padding-top: 25px
            width: 100%
            ul
                margin: 0
                padding: 0
                li
                    list-style-type: none
            a
                position: relative
                font-weight: $bold
                
                font-size: 17px
                padding: 5px 10px
                display: block
                text-decoration: none
                text-transform: uppercase
                transition: all 0.3s
                overflow: hidden
                z-index: 1
                color: $main-blue
            a:before
                content: ""
                display: block
                position: absolute
                top: 0
                left: 0
                right: 0
                bottom: 0
                background-color: $main-blue
                transform: translateX(-101%)
                transition: all 0.3s
                z-index: -1
            a:hover,
            a:focus,
            a:active,
            a.active
                color: white
                text-decoration: none
                background-color: transparentize(white, 0.7)
                &:before
                    transform: translateX(0)

        .nav-bottom
            padding-bottom: 10vh
            border-top: solid 2px $border-color

@media screen and (max-width: $screen-lg)
    .app aside
        transition: all 0.3s
        position: absolute
        height: 100vh
        top: 0
        left: 0
        z-index: 998
        transform: translateX(-#{$aside_width})
        &:before
            content: ''
            display: block
            position: absolute
            left: 0
            top: 0
            width: 0
            height: 100vh
            background-color: transparentize($grey, 0.5)
            z-index: -1
            opacity: 0
            transition: all 0.3s

        .menu-burger
            display: flex
            // justify-content: space-evenly
            gap: 10px
            justify-content: center
            align-items: center
            flex-direction: column
            position: absolute
            z-index: 1
            top: 0
            left: $aside_width
            background-color: transparent
            border: 0
            border-radius: 0
            margin: 0
            padding: 10px
            height: $header_height
            width: $header_height
            opacity: 1
            transition: all 0.3s
            .menu-burger-slice
                display: block
                position: relative
                background-color: white
                height: 4px
                width: 30px
                border-radius: 2px
                opacity: 1
                transform-origin: left center
                transform: rotate(0)
                transition: .25s ease-in-out

        &.in
            transform: translateX(0)
            &:before
                opacity: 1
                transition: opacity 0.3s

            .menu-burger
                left: 150px
                height: 50px
                width: 50px
                .menu-burger-slice:nth-child(1)
                    transform: rotate(45deg)
                    background: $main-blue
                    left: 4.5px
                    top: 4px 
                .menu-burger-slice:nth-child(2)
                    width: 0
                    opacity: 0
                    color: $main-blue
                .menu-burger-slice:nth-child(3)
                    transform: rotate(-45deg)
                    background: $main-blue
                    left: 4.5px
                    bottom: 2px

            .aside-content-wrapper
                // Edge fallback
                overflow: auto
                overflow: hidden auto
                scrollbar-width: thin
                &::-webkit-scrollbar
                    background-color: darken($green, 7)
                    width: 7px
                &::-webkit-scrollbar-thumb
                    background-color: transparentize(white, 0.1)
                    -webkit-border-radius: 1ex
                    border-radius: 4px

        .aside-content-wrapper
            align-items: flex-start
            justify-content: center
            padding-top: 50px
            position: static



@media screen and (max-width: $screen-sm), screen and (max-height: $aside_height)
    .app aside
        font-size: 18px
        width: $aside_width_responsive
        min-width: $aside_width_responsive // due to flex

        .aside-content-wrapper
            width: $aside_width_responsive // due to fixed
            min-width: $aside_width_responsive

            .logo-img-wrapper 
                .profile-img
                    width: 80px
                    height: 80px
                h1 
                    font-size: 20px


@media screen and (max-height: 700px)
    .app aside
        .aside-content-wrapper
            display: block
            nav.nav-bottom
                padding-bottom: 1em
            nav.nav-top
                padding-bottom: 1em

@media screen and (max-height: 650px)
    .app aside .aside-content-wrapper
        position: static
        width: 100%
        min-width: 1px
        nav
            flex-grow: 0

    