.app .segment
    background: $bg-blue
    border-radius: 5px
    padding: 20px
    h3
        font-size: 20px
        font-weight: $bold
        
        margin: 0
        text-transform: uppercase
    p
        color: $main-blue