.app.wrap-edge-message 
    .page
        padding-top: 48px
    .edge-message
        position: fixed
        left: 0
        right: 0
        top: 0
        height: 48px
        background-color: lighten($yellow, 25)
        padding: 0 15px
        z-index: 9999
        display: flex
        overflow: hidden
        text-overflow: ellipsis
        p
            margin: auto
        a
            text-decoration: underline
            color: #0056b3
        .close
            height: 20px
            background-color: transparent
            border: 0
            cursor: pointer
            padding: 0
            line-height: 0
            vertical-align: middle
            align-self: center
            img
                width: 15px
                height: 15px