.my-leases 
    .lease-list
        display: grid
        grid-template-columns: repeat(3, minmax(0, 1fr))
        grid-gap: 30px
        .lease-teaser 
            display: flex
            flex-direction: column
            justify-content: space-between
            position: relative
            min-height: 150px
            padding: 20px
            border-radius: 5px
            background-color: white
            transition: all 0.3s
            &:hover
                background-color: $second-blue
                color: white
            
            h2
                font-size: 25px
                padding-bottom: 30px
                text-transform: uppercase
                text-align: center

            .lease-nb-lot
                display: flex
                flex-direction: row
                font-weight: $bold
                font-size: 17px

            .extended-link
                font-weight: $bold
                font-size: 21px
    
    .modal
        font-size: 24px
        .modal-scrollbar
            padding: 40px 40px 0
            h2
                text-align: center
                font-size: 26px
                margin-bottom: 60px
            p
                text-align: center
                margin-bottom: 60px

            .form
                font-size: 24px
                max-width: 100%
                .form-item
                    text-align: center
                    justify-content: center
                    margin-bottom: 40px
                .submit-button-wrapper
                    padding-top: 0
                    padding-bottom: 15px

@media screen and (max-width: $screen-xl)
    .my-leases .lease-list
        grid-template-columns: repeat(2, minmax(0, 1fr))

@media screen and (max-width: $screen-sm)
    .my-leases .lease-list
        grid-template-columns: repeat(1, minmax(0, 1fr))
        