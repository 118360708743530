.disappeared
    display: none !important
    // to hide text : bootstrap class : sr-only
    
.grow
    flex-grow: 1

.text-green
    color: $green
.text-red
    color: $red
.text-blue
    color: $main-blue
.text-orange
    color: $orange

// For PrintJs
iframe
    border: 0

.app
    display: flex
    flex-direction: row
    min-height: 100vh
    font-family: $font-family-base
    background-color: $main-blue
    color: $main-blue
    position: relative
    h1
        color: white
        width: 100%
        margin: 0
        text-transform: uppercase
        font-size: 22px
    h4
        margin: 0
    // Link
    a
        &.extended-link:before
            content: ''
            position: absolute
            display: block
            left: 0
            right: 0
            top: 0
            bottom: 0
            z-index: 10
    p
        color: white
        font-size: 18px

    main a
        text-decoration: underline
        color: inherit

    main h2 a
        text-decoration: none
        &:hover,
        &:focus,
        &:active
            text-decoration: none

    // Text
    strong, b
        font-weight: 600

    blockquote
        font-style: italic
        font-size: 24px

    // Tools
    select
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        cursor: pointer
        padding-right: 22px !important
        background: url('../images/pictogrammes/arrow-blue.svg') no-repeat right 5px center
        background-size: 12px auto

    .solde-amount
        display: flex
        flex-grow: 1
        align-items: center
        justify-content: center

    .standfirst
        margin-bottom: 15px
        .item-selector
            display: inline-block
            margin-top: 0
            margin-right: 25px
            &:last-child
                margin-right: 0
        .toggle-btn-wrapper
            margin-bottom: 0
    .standfirst
        &.toggle-plus-select
            display: flex
            flex-direction: column
            gap: 10px
            color: white
            margin: 20px 0
            h3
                color: white
                text-transform: uppercase
                margin-bottom: 15px
    
    .scrollable-table
        overflow: auto

    .item-selector
        align-self: flex-start
        margin-top: 10px
        label
            padding-right: 10px
            margin: 0
            font-size: 20px
        select
            background-color: $bg-blue
            border-radius: 10px
            color: $main-blue
            font-weight: $medium
            border: 0
            padding: 5px 10px

    .legal-links
        margin-top: 10px
        font-size: 12px
        a       
            margin-right: 15px
            color: white
            text-decoration: underline
            text-transform: uppercase

    // Log
    .user-log-wrapper
        position: fixed
        z-index: 99
        top: $header_height
        left: $aside_width
        right: 0
        padding: 15px 15px 0
        text-align: center
        max-width: 1170px
        margin-left: auto
        margin-right: auto
        transition: all 0.3s
        .user-log
            display: inline-block
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.3)
            padding: 8px 30px
            border-radius: 10px
            opacity: 1
            transition: all 0.3s
            &.success
                color: #155724
                background-color: #d4edda
                border: 1px solid #c3e6cb
            &.error
                color: #721c24
                background-color: #f8d7da
                border-color: #f5c6cb
        &.hidden .user-log
            opacity: 0
        @media screen and (max-width: $screen-xl)
            left: 0
            max-width: 100%
    &.unscrollable .user-log-wrapper,
    &.not-logged-page .user-log-wrapper
        left: 0
        top: 15px
        z-index: 1000

    // Main Content
    .page
        background: $main-blue
        flex-grow: 1 // width 100% : parent flex direction row
        min-width: 0
        padding: 0 30px 30px 30px
        main
            height: calc(100% - #{$header_height})

    &.unscrollable
        overflow: hidden
        height: 100vh

    @media screen and (max-width: $screen-xs)
        .item-selector
            width: 180px
            font-size: 12px
            label
                display: none

@media screen and (max-width: $screen-md)
    .app 
        a
            font-size: 13px !important
        h1
            font-size: 17px !important
        h2,
        h3
            font-size: 20px !important
        span,
        p
            font-size: 12px !important
        table
            thead,
            tbody
                font-size: 12px !important

@media screen and (max-width: $screen-sm), screen and (max-height: $screen-xs) and (max-width: $screen-lg)
    .app
        display: block
        main
            height: auto
            h3
                font-size: 20px
        blockquote
            font-size: 14px
