.app
    .form-item-list-incident
        display: flex
        flex-direction: row
        flex-wrap: wrap
        input,
        textarea
            display: inline-block
            flex-grow: 1
            margin-right: 10px
            border-radius: 5px
            line-height: 1
            color: $grey
            padding: 5px
            border: none
        textarea
            height: 150px
        .input-file
            display: none
        .fields
            width: 50%
            margin-right: 30px
        .incident-form-info
            font-style: italic
            margin-top: 30px
        
    .incident-image-wrapper
        display: inline-flex
        align-items: center
        align-self: center
        justify-content: center
        max-width: 100%
        overflow: hidden
        width: 100px
        height: auto
        max-height: 100px
        &:first-child
            margin-right: 15px
        #incident-file-0,
        #incident-file-1
            width: 100px
            height: 100px
        .incident-image-wrapper-overlay
            transition: all 0.3s
            position: relative
            &:before
                content: ''
                display: block
                position: absolute
                left: 0
                right: 0
                top: 0
                bottom: 0
                z-index: 1
                border-radius: 15px
                transition: all 0.3s
            &:hover,
            &:focus,
            &:active
                &:before
                    background: url('../../images/pictogrammes/close-white.svg') transparentize(black, 0.3) no-repeat center center
                    background-size: 30px
                    cursor: pointer
            img
                width: auto
                max-width: 300px
                height: auto
                max-height: 300px
                border-radius: 15px
                @media screen and (max-width: 360px)
                    max-width: 100%

    .table-incidents
        td
            padding: 30px
            text-align: left !important
        td:last-child
            text-align: center !important
        .ellipsed
            -moz-line-clamp: 10
            -webkit-line-clamp: 10   
            max-height: 10 * 1.5rem
            line-height: 1.5rem