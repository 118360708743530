.app .card
    display: flex
    height: auto
    width: auto
    font-size: 18px
    overflow: hidden
    background: $card
    border: none
    border-radius: 5px
    p
        color: white
    &.unscrollable
        max-height: none !important
        .card-body
            overflow: auto
    .card-header
        text-align: center
        width: auto
        flex-shrink: 0
        padding: 15px
        border: 0
        border-radius: 5px
        background-color: $card-header-blue
        h1,
        h2
            line-height: 1
            margin: 0 30px
            text-transform: uppercase
            font-weight: $medium
        h1
            color: white
            padding-bottom: 20px
            width: fit-content
            border-bottom: 2px solid white
            font-size: 30px
        h2
            margin: 0
            font-size: 25px
            
            font-weight: $bold
            color: $main-blue
            width: 100%
    .card-body
        // Edge fallback
        display: flex
        flex-direction: column
        justify-content: space-between
        height: fit-content
        overflow: auto
        scrollbar-color: $light-grey white
        scrollbar-width: thin
        &::-webkit-scrollbar
            background-color: white
            height: 12px
            width: 12px
            border-radius: 6px
        &::-webkit-scrollbar-thumb
            background-color: $light-grey
            border-radius: 6px
        p
            color: $main-blue
        h3
            color: $main-blue
            font-size: 37px
            font-weight: $bold
            text-decoration: none
            text-transform: uppercase
            margin: 0
        h2
            text-align: center
            color: $main-blue
       
    .card-footer 
        background-color: $main-blue
        margin-top: 10px
        font-size: 12px
        border: 0
        padding: 0
        a
            color: white
            text-decoration: underline
            margin-right: 15px  

    @media screen and (max-width: $screen-xl)
        .card
            .card-header
                h2
                    font-size: 22.5px
            
    @media screen and (max-width: $screen-sm), screen and (max-height: $screen-sm) and (max-width: $screen-lg)
        .app .card
            height: auto
            min-height: 1px
            border: 0
            border-radius: 0
            font-size: 14px
            overflow: auto
            &:first-child
                border-top: 0
            &:last-child
                margin-bottom: 0
            .card-row
                padding: 15px 25px
            .card-header
                border-radius: 0
                h1,
                h2
                    font-size: 24px
            .card-body
                border-radius: 0
                padding: 15px
                overflow: auto
                &::-webkit-scrollbar
                    height: 7px
                    width: 7px
                &::-webkit-scrollbar-thumb
                    -webkit-border-radius: 1ex
                    border-radius: 4px
                &.table-wrapper
                    padding: 15px 0 0
                    p
                        padding: 0 15px
                    .btn-wrapper
                        padding-bottom: 15px
                > :last-child
                    padding-bottom: 0
            > :last-child
                margin-bottom: 0
                .card-body
                    padding-bottom: 0